import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/@tanstack-react-query-virtual-5c7bfd1dba/0/cache/@tanstack-react-query-npm-5.59.0-4fd85dc5e2-035f1039a8.zip/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/next-themes-virtual-89026ec027/0/cache/next-themes-npm-0.3.0-624efd7b90-72bd670ad6.zip/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/next-virtual-a8c5709b84/0/cache/next-npm-14.2.20-903d2e69b2-a2e214762c.zip/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-primary\",\"adjustFontFallback\":true,\"preload\":true}],\"variableName\":\"primaryFont\"}");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/next-virtual-a8c5709b84/0/cache/next-npm-14.2.20-903d2e69b2-a2e214762c.zip/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Outfit\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-secondary\",\"adjustFontFallback\":true,\"preload\":true}],\"variableName\":\"secondaryFont\"}");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/react-toastify-virtual-79ec1e622f/0/cache/react-toastify-npm-9.1.3-2f82dd0b41-51de1e51e9.zip/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["WagmiContext","WagmiProvider"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/context.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAccount"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useAccount.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAccountEffect"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useAccountEffect.js");
;
import(/* webpackMode: "eager", webpackExports: ["useBalance"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useBalance.js");
;
import(/* webpackMode: "eager", webpackExports: ["useBlock"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useBlock.js");
;
import(/* webpackMode: "eager", webpackExports: ["useBlockNumber"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useBlockNumber.js");
;
import(/* webpackMode: "eager", webpackExports: ["useBlockTransactionCount"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useBlockTransactionCount.js");
;
import(/* webpackMode: "eager", webpackExports: ["useBytecode"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useBytecode.js");
;
import(/* webpackMode: "eager", webpackExports: ["useCall"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useCall.js");
;
import(/* webpackMode: "eager", webpackExports: ["useChainId"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useChainId.js");
;
import(/* webpackMode: "eager", webpackExports: ["useChains"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useChains.js");
;
import(/* webpackMode: "eager", webpackExports: ["useClient"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["useConfig"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useConfig.js");
;
import(/* webpackMode: "eager", webpackExports: ["useConnect"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useConnect.js");
;
import(/* webpackMode: "eager", webpackExports: ["useConnections"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useConnections.js");
;
import(/* webpackMode: "eager", webpackExports: ["useConnectorClient"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useConnectorClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["useConnectors"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useConnectors.js");
;
import(/* webpackMode: "eager", webpackExports: ["useDeployContract"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useDeployContract.js");
;
import(/* webpackMode: "eager", webpackExports: ["useDisconnect"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useDisconnect.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEnsAddress"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useEnsAddress.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEnsAvatar"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useEnsAvatar.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEnsName"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useEnsName.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEnsResolver"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useEnsResolver.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEnsText"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useEnsText.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEstimateFeesPerGas"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useEstimateFeesPerGas.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEstimateGas"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useEstimateGas.js");
;
import(/* webpackMode: "eager", webpackExports: ["useEstimateMaxPriorityFeePerGas"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useEstimateMaxPriorityFeePerGas.js");
;
import(/* webpackMode: "eager", webpackExports: ["useFeeHistory"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useFeeHistory.js");
;
import(/* webpackMode: "eager", webpackExports: ["useGasPrice"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useGasPrice.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteReadContracts"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useInfiniteReadContracts.js");
;
import(/* webpackMode: "eager", webpackExports: ["usePrepareTransactionRequest"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/usePrepareTransactionRequest.js");
;
import(/* webpackMode: "eager", webpackExports: ["useProof"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useProof.js");
;
import(/* webpackMode: "eager", webpackExports: ["usePublicClient"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/usePublicClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["useReadContract"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useReadContract.js");
;
import(/* webpackMode: "eager", webpackExports: ["useReadContracts"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useReadContracts.js");
;
import(/* webpackMode: "eager", webpackExports: ["useReconnect"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useReconnect.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSendTransaction"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useSendTransaction.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSignMessage"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useSignMessage.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSignTypedData"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useSignTypedData.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSimulateContract"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useSimulateContract.js");
;
import(/* webpackMode: "eager", webpackExports: ["useStorageAt"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useStorageAt.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSwitchAccount"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useSwitchAccount.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSwitchChain"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useSwitchChain.js");
;
import(/* webpackMode: "eager", webpackExports: ["useToken"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useToken.js");
;
import(/* webpackMode: "eager", webpackExports: ["useTransaction"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useTransaction.js");
;
import(/* webpackMode: "eager", webpackExports: ["useTransactionConfirmations"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useTransactionConfirmations.js");
;
import(/* webpackMode: "eager", webpackExports: ["useTransactionCount"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useTransactionCount.js");
;
import(/* webpackMode: "eager", webpackExports: ["useTransactionReceipt"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useTransactionReceipt.js");
;
import(/* webpackMode: "eager", webpackExports: ["useVerifyMessage"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useVerifyMessage.js");
;
import(/* webpackMode: "eager", webpackExports: ["useVerifyTypedData"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useVerifyTypedData.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWaitForTransactionReceipt"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useWaitForTransactionReceipt.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWalletClient"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useWalletClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWatchAsset"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useWatchAsset.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWatchBlockNumber"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useWatchBlockNumber.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWatchBlocks"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useWatchBlocks.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWatchContractEvent"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useWatchContractEvent.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWatchPendingTransactions"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useWatchPendingTransactions.js");
;
import(/* webpackMode: "eager", webpackExports: ["useWriteContract"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hooks/useWriteContract.js");
;
import(/* webpackMode: "eager", webpackExports: ["Hydrate"] */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/wagmi-virtual-0a37f0b974/0/cache/wagmi-npm-2.12.25-4792510c09-18510c1a75.zip/node_modules/wagmi/dist/esm/hydrate.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/apps/store-web/components/layout/MainLayout/MainLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/apps/store-web/components/providers/GlobalModalsStore.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/apps/store-web/components/providers/I18nProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/apps/store-web/components/providers/NotificationBannerProvider/NotificationBannerProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/apps/store-web/components/providers/Onboarding/OnboardingProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/apps/store-web/components/providers/OneSignalProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/apps/store-web/components/providers/ProfileMenuProvider/ProfileMenuProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/apps/store-web/components/providers/SnapshotProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/apps/store-web/components/providers/StoreChainProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/apps/store-web/components/providers/StoreNavMenuProvider/StoreNavMenuProvider.tsx");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/apps/store-web/styles/global.css");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/GlobalModalsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalStateProvider"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/GlobalStateProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleAdSenseProvider"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/GoogleAdSenseProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/GoogleTagManagerProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MixPanelProvider"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/MixPanelProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/PasskeyProvider.tsx");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/ReactQuery/QueryProviderSsr.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/SocketProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/ToastProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/TonProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/TurnstileProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/UserAgentDetectProvider.tsx");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/Web3Provider/Web3Provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WidgetContainerProvider"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/common/providers/WidgetContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/magic.ai/frontend/magicsquare/libs/shared/src/hooks/useTelegramMiniAppSdk.ts");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/circle-email.svg");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/circle-linked-in.svg");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/circle-reddit.svg");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/circle-telegram.svg");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/circle-twitter.svg");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/evm-light.svg");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/facebook.svg");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/metamask-round.svg");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/metamask.svg");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/okx.svg");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/phantom.svg");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/trust-wallet.svg");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/libs/uikit/src/assets/svg-icons/wallet-connect.svg");
