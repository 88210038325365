import { useId } from 'react';

import { ADS_CONSTANTS } from './AdsConstants';
import { useAppendScript } from './hooks';

const StoreAppInlineAppFormBottomRight = ({ userId }: { userId?: number }) => {
  const id = useId();

  useAppendScript({ id, userId });

  return (
    <div
      className="
      h-12 p-2 pr-3 border border-base-border-medium rounded-lg
      max-sm:w-full
      lg:h-14 lg:pr-4
      xl:col-span-2 xl:row-start-2 xl:col-start-2 xl:justify-self-end
      max-xl:row-start-2 max-xl:absolute max-xl:right-0 max-xl:bottom-0
      max-lg:row-start-3 max-lg:col-start-1 max-lg:static max-lg:w-80
      max-md:order-4
      "
      id={id}
    >
      <ins
        className={ADS_CONSTANTS.className}
        data-cp-user-id={userId}
        data-key={ADS_CONSTANTS.storeAppInlineAppFormBottomRightDataKey}
      />
    </div>
  );
};

export default StoreAppInlineAppFormBottomRight;
