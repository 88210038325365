import { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

import { ComponentWrapperElementType } from '../../utils/types';

export type ButtonVariant = 'primary' | 'secondary' | 'outline' | 'ghost' | 'custom';

const buttonVariantClassNames: Record<ButtonVariant, string> = {
  primary: 'button-primary',
  secondary: 'button-secondary',
  outline: 'btn-outline',
  ghost: 'btn-ghost',
  custom: 'btn-custom',
};

export interface ButtonProps<As extends ComponentWrapperElementType>
  extends ComponentPropsWithoutRef<'button'> {
  variant?: ButtonVariant;
  label?: string;
  rounded?: boolean;
  size?: 'sm' | 'md' | 'lg';
  isDefaultPaddings?: boolean;
  disabledVisually?: boolean;
  as?: As;
}

const Button = <As extends ComponentWrapperElementType>({
  label,
  variant = 'primary',
  className,
  type = 'button',
  rounded = true,
  size = 'lg',
  children,
  isDefaultPaddings = true,
  disabledVisually,
  as,
  ...props
}: ButtonProps<As> & Omit<ComponentPropsWithoutRef<As>, keyof ButtonProps<As>>): JSX.Element => {
  const Component = as || 'button';
  const variantClassName = buttonVariantClassNames[variant];
  const isVisuallyDisabled = disabledVisually || props.disabled;

  return (
    <Component
      className={classNames(
        'button',
        variantClassName,
        {
          'visually-disabled-bg': isVisuallyDisabled && variant !== 'ghost',
          'visually-disabled-text': isVisuallyDisabled,
          'rounded-full': rounded,
          'rounded-lg': !rounded,
          'py-3': isDefaultPaddings && size === 'lg',
          'py-2': isDefaultPaddings && size === 'md',
          'py-1': isDefaultPaddings && size === 'sm',
        },
        className,
      )}
      type={type}
      {...props}
    >
      {children || label}
    </Component>
  );
};

export default Button;
